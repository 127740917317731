<template>
    <div>
        <Quote/>
        <GoBack next="Izoh qoldiring"/>

        <div class="row justify-content-center" style="margin-top:111px;">
            <div class="col-8">
                <form @submit.prevent="commit">
                    <div class="form-group mb-4">
                        <select
                            v-model="commentUser.course"
                            class="form-control"
                            id="exampleFormControlSelect1"
                        >
                            <option disabled selected hidden>Kursni tanlang...</option>
                            <option
                                v-for="courses of getTakeCourses"
                                :key="courses.id"
                                :value="'/api/courses/'+ courses.id"
                                :label="courses.type.name"
                            >
                            </option>
                        </select>
                    </div>
                    <div class="form-group mb-4 div_input_file">
                        <input
                            type="file"
                            class="hidden_input"
                            ref="hidden_input"
                            @change="chooseFile($event)"
                        >
                        <input
                            type="text"
                            @click="showFileExplorer"
                            placeholder="Faylni tanlang..."
                            class="form-control">
                        <button class="btn btn-primary">
                            <p>Yuklash</p>
                        </button>
                    </div>

                    <div class="form-group mb-4">
                        <div class="input-group">
                            <textarea
                                v-model="commentUser.text"
                                class="form-control area"
                                placeholder="Izoh qoldiring"
                                aria-label="Width textarea"
                            >

                            </textarea>
                        </div>
                    </div>

                    <div class="row justify-content-end">
                        <div class="col-4.5">
                            <button type="submit" class="btn btn-primary "><p>Saqlash</p></button>
                        </div>
                    </div>

                </form>

                <b-modal ref="my-modal-success" hide-footer title="kadirov-dev">
                    <div class="d-block text-center">
                        <h3>Izoh qoldirganingiz uchun raxmat. Izohingiz moderatorga tasdiqlash uchun yuborildi</h3>
                    </div>
                    <b-button
                        class="mt-3 float-end p-1"
                        variant="outline-primary"
                        @click="hideSuccessModal"
                    >
                        Davom etish
                    </b-button>
                </b-modal>

            </div>
        </div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </div>
</template>

<script>
import GoBack from '../components/GoBack.vue';
import Quote from "../components/Quote";
import {mapActions, mapGetters} from "vuex";

export default {
    components: {Quote, GoBack},
    name: "AddCommentPage",
    data() {
        return {
            commentUser: {
                course: 'Kursni tanlang...',
                text: '',
            },
            photo: null,
            show: true
        }
    },
    methods: {
        ...mapActions(['fetchComment', 'fetchTakeCourse']),
        showFileExplorer() {
            this.$refs.hidden_input.click();
        },
        chooseFile(media) {
            this.photo = media.target.files[0];
        },
        commit() {
            console.log(this.commentUser)
            this.fetchComment(this.commentUser)
                .then(() => {
                    this.$refs['my-modal-success'].show()
                })
                .catch(() => {
                    console.log('jo\'natishda xato')
                })
        },
        hideSuccessModal() {
            this.$refs['my-modal-success'].hide()
            this.$router.push('/')
        }
    },
    mounted() {
        this.show = true
        this.fetchTakeCourse()
        this.show = false
    },
    computed: {
        ...mapGetters(['getTakeCourses'])
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');

* {
    padding: 0;
    margin: 0;
}

.form-control {
    border-radius: 10px !important;
    background: #FFFFFF !important;
    border: 1px solid #80007F !important;;
    box-sizing: border-box !important;
    padding-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
}

.justify-content-end .btn-primary {
    text-align: center;
    padding: 5px 60px;
    border-radius: 10px;
    background-color: #80007F;
    font-family: 'Comfortaa', cursive;
    font-size: 16px;
}

.btn-primary {
    color: #fff;
    background-color: #80007F;
    border-color: #80007F;
}

.area {
    height: 237px;
    padding-top: 9px;
    padding-left: 17.04px;
}

div.div_input_file {
    display: flex;
    gap: 2.5%;
}

div.div_input_file > button {
    border: none;
    background: #80007F;
    color: white;
    padding: 0 3%;
    border-radius: 10px;
    width: 161px;
    height: 38px;
}

input.hidden_input {
    display: none;
}
</style>
